import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Play } from 'phosphor-react';
import classNames from 'classnames';

import {
  TUTORIAL_ZONE_PROJECT_QUERY,
  TUTORIAL_ZONE_PROJECT_ANALYZE,
  TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT,
  TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_NOT_COMPUTED,
  TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED,
  TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS,
  TUTORIAL_ZONE_NEW_PROJECT,
} from 'constants/tutorials';
import { trackOpenTutorial } from 'ducks/trackers/actions/tutorials';

import { useStyles } from './styles';

const TutorialButton = ({ id, label, handleStart, customClass }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    handleStart(id);
  });

  return (
    <Button
      className={classNames(classes.tutorialButton, customClass)}
      onClick={handleClick}
      id={id}
    >
      <Play size={20} className={classes.tutorialIcon} />
      {label}
    </Button>
  );
};

/**
 * TutorialCard
 * @param { String } tutorialZone // Where the tutorial card is displayed
 * @param { Array } projectPointsList
 * @param { Boolean } isDemo
 * @param { function } handleStartTutorial
 * @returns {jsx}
 */
const TutorialCard = ({
  tutorialZone,
  projectPointsList,
  isDemo,
  handleStartTutorial,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [hasComputedPoints, setHasComputedPoints] = useState(false);

  // Check which tutorial to display depending on zone
  useEffect(() => {
    if (tutorialZone === TUTORIAL_ZONE_PROJECT_QUERY) {
      setIsDisplayed(!isDemo);
    }
    if (
      tutorialZone === TUTORIAL_ZONE_PROJECT_ANALYZE ||
      tutorialZone === TUTORIAL_ZONE_NEW_PROJECT
    ) {
      setIsDisplayed(true); // Display even if demo project
    }
  }, [tutorialZone, isDemo]);

  // Check points status at init
  useEffect(() => {
    if (projectPointsList && projectPointsList.length > 0) {
      const computedPoints = projectPointsList.filter(
        (point) => point.isComputed
      );
      setHasComputedPoints(computedPoints.length > 0);
    }
  }, [projectPointsList]);

  const startTutorial = useCallback((tutorialId) => {
    dispatch(trackOpenTutorial(tutorialId));
    handleStartTutorial(tutorialId);
  });

  return (
    isDisplayed && (
      <>
        {/* Tutorial Card in New Project page */}
        {tutorialZone === TUTORIAL_ZONE_NEW_PROJECT && (
          <div className={classes.tutorialCardContainer}>
            <div className={classes.tutorialCardTitle}>Tutorials</div>
            <TutorialButton
              id={TUTORIAL_ZONE_NEW_PROJECT}
              label="How to create a new project ?"
              handleStart={startTutorial}
              customClass={classes.centerButton}
            />
          </div>
        )}
        {/* Tutorial Card in Project Dashboard Tab Query */}
        {tutorialZone === TUTORIAL_ZONE_PROJECT_QUERY && (
          <div className={classes.tutorialCardContainer}>
            <div className={classes.tutorialCardTitle}>Tutorials</div>
            <TutorialButton
              id={TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT}
              label="How to create a point ?"
              handleStart={startTutorial}
            />
            {projectPointsList.length > 0 && !hasComputedPoints && (
              <TutorialButton
                id={
                  TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_NOT_COMPUTED
                }
                label="How to obtain statistics ?"
                handleStart={startTutorial}
              />
            )}
            {projectPointsList.length > 0 && hasComputedPoints && (
              <TutorialButton
                id={TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED}
                label="How to view statistics ?"
                handleStart={startTutorial}
              />
            )}
          </div>
        )}
        {/* Tutorial Card in Project Dashboard Tab Analyze */}
        {tutorialZone === TUTORIAL_ZONE_PROJECT_ANALYZE && (
          <div className={classes.tutorialCardContainer}>
            <div className={classes.tutorialCardTitle}>Tutorials</div>
            {projectPointsList.length > 0 && hasComputedPoints && (
              <TutorialButton
                id={TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS}
                label="How to display statistics ?"
                handleStart={startTutorial}
              />
            )}
          </div>
        )}
      </>
    )
  );
};

TutorialCard.propTypes = {
  tutorialZone: PropTypes.string.isRequired,
  projectPointsList: PropTypes.array,
  isDemo: PropTypes.bool,
  handleStartTutorial: PropTypes.func.isRequired,
};

export default React.memo(TutorialCard);
