import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { requestProjects } from 'ducks/projects/actions';
import { selectProjectsCollection } from 'ducks/projects/selectors';
import { PROJECT_NAME_REGEXP } from 'constants/regexp';
import { ONLY_CHARACTERS_AND_NUMBER } from 'constants/errors';

import { useStyles } from './styles';

const NewProjectName = ({ handleProjectName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const projects = useSelector(selectProjectsCollection);
  const projectNamesList = useMemo(
    () => projects.map((project) => project.name),
    [projects]
  );

  useEffect(() => {
    dispatch(requestProjects({ params: { ordering: 'id' } }));
  }, [dispatch]);

  const [newProjectName, setNewProjectName] = useState('');
  const [displayErrorChar, setDisplayErrorChar] = useState(false);
  const [displayErrorNameExists, setDisplayErrorNameExists] = useState(false);

  const handleChange = useCallback(
    (data) => {
      const inputValue = data.target.value;
      setNewProjectName(inputValue);
      // Check no special characters nor blanck in project name
      const checkRegex = new RegExp(PROJECT_NAME_REGEXP);
      const charValid = checkRegex.test(inputValue);
      setDisplayErrorChar(!charValid);
      // Check project name not already exists
      const projectNameExists = projectNamesList?.includes(inputValue);
      setDisplayErrorNameExists(projectNameExists);
      if (charValid && !projectNameExists) {
        handleProjectName(inputValue);
      }
      if (!charValid || projectNameExists) {
        handleProjectName(null);
      }
    },
    [setDisplayErrorChar, projectNamesList]
  );

  return (
    <div id="NewProjectName" className={classes.createProjectSection}>
      <div className={classes.subtitle}>Project name</div>
      <div className={classes.row}>
        <div className={classes.fullWidth}>
          <TextField
            variant="outlined"
            label="Project Name"
            fullWidth
            name="name"
            size="medium"
            value={newProjectName}
            onChange={handleChange}
          />
          {displayErrorChar && (
            <div className={classes.helperText}>
              {ONLY_CHARACTERS_AND_NUMBER}
            </div>
          )}
          {displayErrorNameExists && (
            <div className={classes.helperText}>
              Project name is already taken
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NewProjectName.propTypes = {
  handleProjectName: PropTypes.func.isRequired,
};

export default React.memo(NewProjectName);
