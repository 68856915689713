import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Article, PencilSimpleLine } from 'phosphor-react';

import { useModal } from 'hooks/useModal';
import { NEW_PROJECT_SELECT_OFFER_MODAL } from 'constants/modals';
import { trackOfferSelection } from 'ducks/trackers/actions/projectManagment';

import { useStyles } from './styles';

const NewProjectOffer = ({
  selectedOffer,
  notExpiredOffers,
  handleSaveOffer,
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const openSelectOfferModal = useCallback(() =>
    openModal(NEW_PROJECT_SELECT_OFFER_MODAL, {
      selectedOffer,
      notExpiredOffers,
      handleUpdateSelectedOffer,
    })
  );

  const handleUpdateSelectedOffer = useCallback((updatedOffer) => {
    handleSaveOffer(updatedOffer);
  });

  useEffect(() => {
    if (selectedOffer) dispatch(trackOfferSelection(selectedOffer));
  }, [selectedOffer]);

  return (
    <div id="NewProjectOffer" className={classes.createProjectSection}>
      <div className={classes.subtitle}>Offer Selection</div>
      {selectedOffer && (
        <div key={selectedOffer.id} className={classes.selectedItemContainer}>
          <div className={classes.labelContainer}>
            <Article size={20} />
            {selectedOffer.name}
          </div>
          {notExpiredOffers.length > 1 && (
            <PencilSimpleLine
              size={20}
              onClick={openSelectOfferModal}
              className={classes.cursor}
            />
          )}
        </div>
      )}
    </div>
  );
};

NewProjectOffer.propTypes = {
  selectedOffer: PropTypes.object,
  notExpiredOffers: PropTypes.array,
  handleSaveOffer: PropTypes.func,
};

export default React.memo(NewProjectOffer);
