import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Database, Globe, PencilSimpleLine, Clock } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { trackZoneSelection } from 'ducks/trackers/actions/projectManagment';
import {
  requestMatchingZoneData,
  resetMatchingZoneData,
} from 'ducks/zones/actions';
import {
  selectMatchingZonesData,
  selectMatchingZonesDataSuccess,
} from 'ducks/zones/selectors';
import { useModal } from 'hooks/useModal';
import { NEW_PROJECT_SELECT_REGIONAL_DATASET_MODAL } from 'constants/modals';
import DatasetIcon from 'components/common/DatasetIcon';
import { AVAILABLE_GRIDS } from 'constants/map';

import { useStyles } from './styles';

const NO_WORLD_ZONE = 'No World Zone';

const DatasetDetails = ({ dataset }) => {
  const classes = useStyles();

  const [availableDatasetDimensions, setAvailableDatasetDimensions] = useState(
    []
  );
  const [selectedDatasetDimension, setSelectedDatasetDimension] = useState(
    null
  );

  // Init selection with first value by default
  useEffect(() => {
    setSelectedDatasetDimension(availableDatasetDimensions[0]);
  }, [dataset, availableDatasetDimensions]);

  // Check which dataset dimension are available for selected dataset
  useEffect(() => {
    setAvailableDatasetDimensions([]); // reset
    AVAILABLE_GRIDS.forEach((dimension) => {
      if (dataset[dimension.id] && dataset[dimension.id].length > 0) {
        setAvailableDatasetDimensions((prevState) => [...prevState, dimension]);
      }
    });
  }, [dataset, setAvailableDatasetDimensions]);

  const handleChangeDatasetDimension = useCallback((newId) => {
    const newDatasetDimension = AVAILABLE_GRIDS.find(
      (item) => item.id === newId
    );
    setSelectedDatasetDimension(newDatasetDimension);
  });

  return (
    <div className={classes.detailsContainer}>
      <div className={classes.itemName}>{dataset.name}</div>
      <div className={classes.iconsWrapper}>
        {availableDatasetDimensions.map((availableDimension) => (
          <DatasetIcon
            key={availableDimension.id}
            id={availableDimension.id}
            customClass={
              availableDimension.id === selectedDatasetDimension?.id
                ? 'selectedDimensionIcon'
                : 'dimensionIcon'
            }
            handleClick={handleChangeDatasetDimension}
          />
        ))}
      </div>
      <div className={classes.detailsWrapper}>
        <div className={classes.dimensionLabel}>
          {selectedDatasetDimension?.name}
        </div>
        <div className={classes.dimensionDetailsContainer}>
          {selectedDatasetDimension?.id &&
            dataset[selectedDatasetDimension?.id] && (
              <>
                {dataset[selectedDatasetDimension.id][0] &&
                  dataset[selectedDatasetDimension.id][0].spatialResolution && (
                    <div className={classes.dimensionDetailsItemContainer}>
                      <DatasetIcon id="resolution" customSize={20} />
                      <div className={classes.dimensionDetailsContent}>
                        <div className={classes.dimensionDetailsItemLabel}>
                          Spatial Resolution
                        </div>
                        <div>
                          {
                            dataset[selectedDatasetDimension.id][0]
                              ?.spatialResolution
                          }{' '}
                          {
                            dataset[selectedDatasetDimension.id][0]
                              ?.spatialResolutionUnits
                          }
                        </div>
                      </div>
                    </div>
                  )}
                {dataset[selectedDatasetDimension.id][0] &&
                  dataset[selectedDatasetDimension.id][0].timeStep && (
                    <div className={classes.dimensionDetailsItemContainer}>
                      <Clock size={20} color="#3643BA" />
                      <div className={classes.dimensionDetailsContent}>
                        <div className={classes.dimensionDetailsItemLabel}>
                          Temporal Resolution
                        </div>
                        <div>
                          {dataset[selectedDatasetDimension.id][0]?.timeStep}{' '}
                          {
                            dataset[selectedDatasetDimension.id][0]
                              ?.timeStepUnits
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

const RegionalCard = ({
  selectedDatasetType,
  regionalMatchingZones,
  defaultRegionalDataset,
  handleRegionalDataset,
  handleSaveRegionalDataset,
}) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const openSelectRegionalDatasetModal = useCallback(() => {
    openModal(NEW_PROJECT_SELECT_REGIONAL_DATASET_MODAL, {
      defaultRegionalDataset,
      regionalMatchingZones,
      handleUpdateSelectedRegionalDataset,
    });
  }, [defaultRegionalDataset, regionalMatchingZones]);

  const handleUpdateSelectedRegionalDataset = useCallback(
    (updatedRegionalDataset) => {
      handleSaveRegionalDataset(updatedRegionalDataset);
    }
  );

  const handleClick = useCallback(() => {
    if (regionalMatchingZones?.length > 0) handleRegionalDataset();
  });

  return (
    <div
      className={classNames(classes.itemCard, {
        [classes.selectedItemCard]: selectedDatasetType === 'regional',
        [classes.clickableItemCard]:
          selectedDatasetType === 'worldwide' &&
          regionalMatchingZones?.length > 0,
        [classes.disabledItemCard]: regionalMatchingZones?.length === 0,
      })}
      onClick={handleClick}
    >
      <div className={classes.itemRowContainer}>
        <div className={classes.itemTitle}>
          <Database size={20} /> High-Resolution{' '}
          <div className={classes.regionalTag}>Regional</div>
        </div>
        {regionalMatchingZones?.length > 1 && (
          <PencilSimpleLine
            size={20}
            className={classes.cursor}
            onClick={openSelectRegionalDatasetModal}
          />
        )}
      </div>
      {defaultRegionalDataset && selectedDatasetType === 'regional' && (
        <DatasetDetails dataset={defaultRegionalDataset} />
      )}
    </div>
  );
};

const NoMatchingZonesMessages = ({ errorNoMatchingZone }) => {
  const classes = useStyles();
  return errorNoMatchingZone ? (
    <div className={classes.warning}>
      There is no matching zone in your offer for this set of points
    </div>
  ) : (
    <div className={classes.info}>
      Select at least one point on map to see dataset available for your
      selection.
    </div>
  );
};

const DatasetCards = ({
  allMatchingZonesByType,
  defaultRegionalDataset,
  selectRegionalDataset,
  updateRegionalDataset,
  selectedDatasetType,
  forceGlobalDataset,
}) => {
  const classes = useStyles();
  const matchingZonesDataSuccess = useSelector(selectMatchingZonesDataSuccess);
  const [displayCards, setDisplayCards] = useState(false);

  // Check all data is available before display cards
  useEffect(() => {
    const ready = Boolean(
      matchingZonesDataSuccess &&
        allMatchingZonesByType &&
        allMatchingZonesByType.worldDefaultZone &&
        allMatchingZonesByType.regionalZones
    );
    setDisplayCards(ready);
  }, [matchingZonesDataSuccess, allMatchingZonesByType, setDisplayCards]);

  return !displayCards ? (
    <CircularProgress size={25} thickness={3} />
  ) : (
    <>
      <RegionalCard
        selectedDatasetType={selectedDatasetType}
        regionalMatchingZones={allMatchingZonesByType?.regionalZones}
        defaultRegionalDataset={defaultRegionalDataset}
        handleRegionalDataset={selectRegionalDataset}
        handleSaveRegionalDataset={updateRegionalDataset}
      />
      {/* Worldwide Card */}
      <div
        className={classNames(classes.itemCard, {
          [classes.selectedItemCard]: selectedDatasetType === 'worldwide',
          [classes.clickableItemCard]:
            selectedDatasetType === 'regional' &&
            allMatchingZonesByType.worldDefaultZone !== NO_WORLD_ZONE,
          [classes.disabledItemCard]:
            selectedDatasetType === 'regional' &&
            allMatchingZonesByType.worldDefaultZone === NO_WORLD_ZONE,
        })}
        onClick={forceGlobalDataset}
      >
        <div className={classes.itemRowContainer}>
          <div className={classes.itemTitle}>
            <Globe size={20} /> Global Data{' '}
            <div className={classes.worldwideTag}>Worldwide</div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewProjectDataset = ({
  errorNoMatchingZone,
  selectedMatchingZoneId,
  allMatchingZonesIds,
  handleUpdateSelectedMatchingZone,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedDatasetType, setSelectedDatasetType] = useState('worldwide');
  const [defaultRegionalDataset, setDefaultRegionalDataset] = useState(null);

  const allMatchingZones = useSelector(selectMatchingZonesData);
  // Set regional & world zones
  const allMatchingZonesByType = useMemo(() => {
    const regionalZonesReady = allMatchingZones.filter((item) => !item.isWorld);
    const worldZonesReady = allMatchingZones.filter(
      (item) => item.isWorld && !item.isPrivate
    );
    return {
      regionalZones: regionalZonesReady,
      worldZones: worldZonesReady,
      worldDefaultZone:
        worldZonesReady?.length > 0 ? worldZonesReady[0] : NO_WORLD_ZONE,
    };
  }, [allMatchingZones]);
  // Save details for selected dataset
  const selectedDataset = useMemo(
    () => allMatchingZones.find((item) => item.id === selectedMatchingZoneId),
    [allMatchingZones, selectedMatchingZoneId]
  );

  // Get details data for each matching zone
  useEffect(() => {
    if (!errorNoMatchingZone && allMatchingZonesIds?.length > 0) {
      dispatch(resetMatchingZoneData()); // Reset when ids change
      allMatchingZonesIds.forEach((matchingZoneId) => {
        dispatch(requestMatchingZoneData(matchingZoneId));
      });
    }
  }, [dispatch, allMatchingZonesIds, errorNoMatchingZone]);

  // Set details for selected matching zone id
  useEffect(() => {
    if (selectedMatchingZoneId && allMatchingZones?.length > 0) {
      const selectedMatchingZoneDetails = allMatchingZones.find(
        (zone) => zone.id === selectedMatchingZoneId
      );
      if (selectedMatchingZoneDetails && !selectedMatchingZoneDetails.isWorld) {
        setDefaultRegionalDataset(selectedMatchingZoneDetails);
        setSelectedDatasetType('regional');
      }
    }
  }, [
    selectedMatchingZoneId,
    allMatchingZones,
    setDefaultRegionalDataset,
    setSelectedDatasetType,
  ]);

  // Set "worldwide" by default, else set "regional"
  useEffect(() => {
    if (selectedMatchingZoneId) {
      if (selectedDataset) {
        const selectedDatasetTrackingData = {
          id: selectedDataset?.id,
          name: selectedDataset?.name,
          description: selectedDataset?.description,
          shapefile: selectedDataset?.shapefile,
          has2DStatistics: selectedDataset?.has2DStatistics,
          isActive: selectedDataset?.isActive,
          isCyclonic: selectedDataset?.isCyclonic,
          isDemo: selectedDataset?.isDemo,
          isPrivate: selectedDataset?.isPrivate,
          isWorld: selectedDataset?.isWorld,
          geom: selectedDataset?.geom,
        };
        dispatch(trackZoneSelection(selectedDatasetTrackingData));
        setSelectedDatasetType(
          selectedDataset.isWorld ? 'worldwide' : 'regional'
        );
      }
    } else {
      setSelectedDatasetType('worldwide'); // reset
    }
  }, [
    dispatch,
    selectedDataset,
    selectedMatchingZoneId,
    setSelectedDatasetType,
  ]);

  const forceGlobalDataset = useCallback(() => {
    setSelectedDatasetType('worldwide');
    if (
      allMatchingZonesByType.worldDefaultZone &&
      allMatchingZonesByType.worldDefaultZone?.id !== selectedMatchingZoneId
    ) {
      handleUpdateSelectedMatchingZone(allMatchingZonesByType.worldDefaultZone);
    }
  }, [selectedMatchingZoneId, allMatchingZonesByType]);

  const selectRegionalDataset = useCallback(() => {
    if (selectedDatasetType === 'worldwide') {
      setSelectedDatasetType('regional');
      handleUpdateSelectedMatchingZone(selectedDataset);
    }
  });

  const updateRegionalDataset = useCallback((updatedRegionalDataset) => {
    setSelectedDatasetType('regional');
    handleUpdateSelectedMatchingZone(updatedRegionalDataset);
  });

  return (
    <div id="NewProjectDataset" className={classes.createProjectSection}>
      <div className={classes.subtitle}>Dataset Selection</div>
      {allMatchingZonesIds?.length === 0 ? (
        <NoMatchingZonesMessages errorNoMatchingZone={errorNoMatchingZone} />
      ) : (
        <DatasetCards
          allMatchingZonesByType={allMatchingZonesByType}
          defaultRegionalDataset={defaultRegionalDataset}
          selectRegionalDataset={selectRegionalDataset}
          updateRegionalDataset={updateRegionalDataset}
          selectedDatasetType={selectedDatasetType}
          forceGlobalDataset={forceGlobalDataset}
        />
      )}
    </div>
  );
};

NewProjectDataset.propTypes = {
  errorNoMatchingZone: PropTypes.bool,
  selectedMatchingZoneId: PropTypes.number,
  allMatchingZonesIds: PropTypes.array,
  handleUpdateSelectedMatchingZone: PropTypes.func,
};

export default React.memo(NewProjectDataset);
